import { createApp } from "vue";
import _ from "lodash";
import VueSlideUpDown from "vue-slide-up-down";
import { axios } from "~/libs/vp-api-axios";

class RegistrationEmailsForm {
  static start() {
    document.addEventListener("DOMContentLoaded", () => {
      // #formがなければ実行しない
      if (document.getElementById("form") === null) return;

      const app = createApp({
        el: "#form",
        components: { "slide-up-down": VueSlideUpDown },
        data() {
          return {
            username: document.querySelector("[v-model='username']"),
            username_message: "",
            isDisabled: false
          };
        },
        watch: {
          username: {
            handler: _.debounce(function() {
              const path = "/join/api_email_overlap_check";
              const params = { email: this.username };
              axios.post(path, params).then((response) => {
                const attention = "<span class='tag_form_attention text_bold'>注</span>";
                var msg = "";
                if (response.data.result === "ng") {
                  msg = "ご入力頂いた内容がメールアドレスの形式をしていません。";
                  this.username_message = attention + msg;
                } else if (response.data.result === "overlap") {
                  msg = this.username + " は既に登録されています。<br />登録された覚えのない方はsetting@valuepress.co.jpまでご連絡ください。<br />パスワードをお忘れの方は、<a href='/recover' target='_blank'>パスワード再発行フォーム<img src='/static/image/other_window_icon.png' style='padding:0 3px;' border='0' width='11' height='11' /></a>よりパスワードの再発行を行ってください。";
                  this.username_message = attention + msg;
                } else if (response.data.result === "free_mail_domain") {
                  msg = "不正利用（なりすまし配信など）防止対策として、下記メールアドレスでのご登録をご検討ください。<br />・自社運営サイトのドメインと同一のメールアドレス<br />・自社運営サイト上に表記されているメールアドレス<br />現在のメールアドレスのままご登録の場合、ご利用前に確認プロセスが発生する場合があります。<br /><br />個人や個人事業主の方などで独自ドメインのメールアドレスをお持ちでない場合は、<br />このまま「認証メールを送信する」よりお進みください。";
                  this.username_message = attention + msg;
                } else if (response.data.result === "ok") {
                  this.username_message = "ご登録可能なメールアドレスです。";
                }
              }).catch((_e) => {
              });
            }, 1000),
            deep: true
          }
        },
        methods: {
          onClick() {
            if (this.username == "" ) {
              const attention = "<span class='tag_form_attention text_bold'>注</span>";
              this.username_message = attention + "メールアドレスを入力してください";
              return false;
            }
            this.isDisabled = true;
            this.registration_email();
          },
          registration_email() {
            const path = "/join/check_input";
            const params = { user_account_registration_email: { email: this.username } };
            axios.post(path, params).then((response) => {
              const attention = "<span class='tag_form_attention text_bold'>注</span>";
              if (response.data.result === "ok") {
                document.location = "join/finish";
              } else if (response.data.result === "overlap") {
                this.username_message = attention + this.username + "は既に登録されています。";
                this.isDisabled = false;
                return false;
              } else if (response.data.result === "not_mail_address") {
                this.username_message = attention + this.username + "ご入力頂いた内容がメールアドレスの形式をしていません。";
                this.isDisabled = false;
                return false;
              } else if (response.data.result === "no_mail_address") {
                this.username_message = attention + this.username + "メールアドレスを入力してください。";
                this.isDisabled = false;
                return false;
              } else {
                this.username_message = attention + this.username + "エラーが発生しました。";
                this.isDisabled = false;
                return false;
              }
            }).catch((_e) => {
              this.isDisabled = false;
            });
          }
        }
      });
      app.mount("#form");
    });
  }
}

export default RegistrationEmailsForm;

import router from "~/common/router";
import { createApp } from "vue";
import TopPageContents from "~/components/StaticPages/TopPages/TopPageContents";
import * as dayjs from "dayjs";
import FavButton from "~/components/Common/FavButton";
import "@fortawesome/fontawesome-free/js/all";
router("static_pages", ["top"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    const idxReleaseApp = createApp({
      components: { TopPageContents }
    });
    idxReleaseApp.config.globalProperties.$dayjs = dayjs;
    idxReleaseApp.mount("#idx_release");
    const idxMainrelease = createApp({
      components: { FavButton }
    });
    idxMainrelease.mount("#idx_mainrelease");
  });

  // MEMO #14663 トップページサイドメニュー：業界(ジャンル)を開閉する
  const genre_list = document.getElementById("genre_list");
  const open_genre_list = document.getElementById("open_genre_list");
  const close_genre_list = document.getElementById("close_genre_list");
  open_genre_list.addEventListener("click", function () {
    genre_list.style.height = "auto";
    open_genre_list.style.display = "none";
    close_genre_list.style.display = "block";
  }, false);
  close_genre_list.addEventListener("click", function () {
    genre_list.style.height = "885px";
    open_genre_list.style.display = "block";
    close_genre_list.style.display = "none";
  }, false);
});

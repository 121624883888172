<template>
  <div>
    <div id="idx_release_list">
      <idx-release
        v-for="idxRelease in idxReleases"
        :key="idxRelease.articleId"
        :idx-release="idxRelease"
        :reader="reader"
        :article-ids="articleIds"
      />
    </div>

    <div id="release_read_box">
      <a
        href="/pressrelease"
        class="idx_release_more_btn"
      >プレスリリースをもっと見る</a>
      <div
        id="release_loading"
        class="hidden_box release_loading_box"
      >
        <img src="/static/image/column3_bx_loader.gif">
      </div>
    </div>
  </div>
</template>

<script>
import { axios } from "~/libs/vp-api-axios";
import IdxRelease from "./IdxRelease";
import ArticleModel from "~/models/ArticleModel";
export default {
  name: "TopPageContents",
  components: { IdxRelease },
  props: {
    reader: {
      type: Boolean,
      default: false,
      required: true
    },
    articleIds: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return { idxReleases: "", };
  },
  component: {
    IdxRelease,
  },
  mounted() {
    this.getIdxReleases();
  },
  methods: {
    async getIdxReleases() {
      try {
        const response = await axios.get("/api/v1/more_releases", { params: { limit: 13 } });
        this.idxReleases = this.createArticleModels(response);
      } catch (error) {
        alert("プレスリリースの取得に失敗しました。時間を置いて再度お試しください。");
      }
    },
    createArticleModels(response) {
      const articles = response.data;
      return articles.map(article => (new ArticleModel(article)));
    },
  }
};
</script>

import Axios from "axios";
import Cookies from "js-cookie";

const tokenElement = document.querySelector("meta[name=csrf-token]");
window.axiosActive = 0;
export const axios = Axios.create({
  headers: {
    "X-CSRF-TOKEN": (tokenElement) ? tokenElement.content : null,
    "Authorization": "Token VP_API"
  },
  responseType: 'json'
});
axios.interceptors.request.use((config) => {
  config.headers.Token = Cookies.get("vp_jwt_auth");

  return config;
});
// feature specでajaxを待てるようにする
axios.interceptors.request.use((config) => {
  window.axiosActive += 1;
  return config;
});
// feature specでajaxを待てるようにする
axios.interceptors.response.use((response) => {
  window.axiosActive -= 1;
  return response;
},(error) => {
  window.axiosActive -= 1
  return Promise.reject(error);
});

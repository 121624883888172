// ランタイム限定ビルドでvue.jsを読み込むためにカルーセルのライブラリは使わない
// spトップカルーセルのページネーションを制御
export function ObserveCarouselPagination () { // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
  if (("IntersectionObserver" in window)) {
    const observer = new IntersectionObserver(switchCarouselPagination, { threshold: [0.8] });
    // カルーセルの要素を監視
    for (let i=0;i<10;i++) {
      if (document.getElementById(`top-slide-${i}`)) {
        observer.observe(document.getElementById(`top-slide-${i}`));
      } else {
        break;
      }
    }
  }
}

// カルーセルのページネーションのターゲットを変更する
function switchCarouselPagination(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      document.querySelector(".carousel_target").classList.remove("carousel_target");
      document.querySelector(`.carousel_index_${entry.target.id.slice(-1)}`).classList.add("carousel_target");
    }
  });
}


import bugsnag from '@bugsnag/js';

window.webpackBugsnagClient = bugsnag.start({
  apiKey: 'e8e3d2b68c784a531c5c08abff8062e6',
  releaseStage: 'production',
  enabledReleaseStages: ['production'],
  // エラーレポートをカスタマイズ
  // ref: https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/
  onError: function (event) {
    // HTTPステータスコード499はユーザーに影響ないため例外飛ばさない
    // ref: https://github.com/radicodeinc/value_press/issues/10125#issuecomment-810073279
    if (event.errors[0].errorMessage === "Request failed with status code 499") return false;
    if ($(".current_login").val() === "true") {
      event.addMetadata("user", {
        id: $(".current_id").val(),
        name: $(".current_name").val(),
        email: $(".current_email").val()
      });
    }
  }
});


<template>
  <infinite-loading-content
    ref="InfiniteLoadingContent"
    @on-loading="onLoading"
  >
    <template #list>
      <div
        v-for="article in articles"
        :key="article.articleId"
        class="pressrelease_article"
      >
        <a
          :href="article.url"
          @click="releaseClick()"
        >
          <div class="article_simple">
            <div
              v-show="article.showTopImage('index')"
              class="release_img01 mr30"
            >
              <span>
                <img
                  :src="article.showTopImage('index')"
                  :srcset="article.showTopImage('index') + ' 1x, ' + article.showTopImage('') + ' 2x'"
                  alt=""
                >
              </span>
            </div>
            <div class="release_info">
              <h2 class="mb10">
                {{ article.title }}
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="article.course" />
              </h2>
              <h3 class="mt05">
                {{ article.companyName }}
                <span class="release_label01">プレスリリース</span>
              </h3>
              <ul class="release_tag">
                <li class="release_tag_time">{{ $dayjs(article.sendDate).format("YYYY年MM月DD日 HH時") }}</li>
                <li class="release_tag_tag">{{ article.genre }}</li>
                <li class="release_tag_tag">{{ article.category }}</li>
              </ul>
            </div>
          </div>
        </a>
        <fav-button
          :reader="reader"
          :article-ids="articleIds"
          :article-id="article.articleId"
        />
      </div>
    </template>
  </infinite-loading-content>
</template>

<script>
import { axios } from "~/libs/vp-api-axios";
import InfiniteLoadingContent from "~/components/Common/InfiniteLoadingContent";
import FavButton from "~/components/Common/FavButton";
import ArticleModel from "~/models/ArticleModel";
import * as md5 from "js-md5";

export default {
  components: {
    InfiniteLoadingContent, FavButton
  },
  props: {
    apiUrl: {
      type: String,
      default: "",
      require: true
    },
    params: {
      type: Object,
      require: false,
      default: () => {}
    },
    reader: {
      type: Boolean,
      require: false,
      default: false
    },
    articleIds: {
      type: Array,
      require: false,
      default: () => []
    }
  },
  data() {
    return {
      articles: history.state && history.state.articles ? this.createArticleModels(history.state.articles) : []
    };
  },
  created() {
    this.historyStateClear();
  },
  methods: {
    onLoading($state) {
      this.fetchArticleList().then(articles => {
        if (articles.length > 0) {
          this.articles = this.articles.concat(this.createArticleModels(articles));
          $state.loaded();
        } else {
          $state.complete();
        }
      }).catch(() => {
        $state.error();
      }).finally(() => {
        this.listContentIsLoaded();
      });
    },
    fetchArticleList() {
      return new Promise((resolve, reject) => {
        const articleIds = this.articles.map(article => (article.article_id));
        const viewingArticleIdHash = md5(articleIds);
        axios.get(this.apiUrl, {
          params: Object.assign({
            limit: 10,
            offset: this.articles.length,
            viewing_article_id_hash: viewingArticleIdHash
          }, this.params)
        }).then((response) => {
          resolve(response.data);
        }).catch((e) => {
          reject(e);
        });
      });
    },
    listContentIsLoaded() {
      this.$refs.InfiniteLoadingContent.isLoaded();
    },
    releaseClick() {
      // ブラウザバック用に現在のページ情報を保存
      history.replaceState(JSON.parse(JSON.stringify({ articles: this.articles })), "");
    },
    historyStateClear() {
      history.replaceState({}, "");
    },
    createArticleModels(articles) {
      return articles.map(article => (new ArticleModel(article)));
    },
  }
};
</script>

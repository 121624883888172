import RecaptchaV3 from "~/components/Common/RecaptchaV3";
import { createApp } from "vue";

class Recaptcha {
  static start() {
    document.addEventListener("DOMContentLoaded", () => {
      // 一旦非ログインページを対象にする
      if(location.pathname.match(/users|partners|readers|admins/)){ return; }
      const app = createApp({
        components: {
          RecaptchaV3
        }
      });

      app.mount("#recaptcha-v3");
    });
  }
}

export default Recaptcha;

import router from "~/common/router";
import { createApp } from "vue";
import ArticleList from "~/components/Articles/ArticleList";
import * as dayjs from "dayjs";

router("article_categories", ["show"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    const app = createApp({
      components: { ArticleList },
      data() {
        return {
          contentsSideRightIsFixed: false,
        };
      },
      mounted() {
        window.addEventListener("scroll", this.handleContentsRightSide);
      },
      destroyed() {
        window.removeEventListener("scroll", this.handleContentsRightSide);
      },
      methods: {
        handleContentsRightSide() {
          this.contentsSideRightIsFixed = window.scrollY >= 267;
        },
      },
    });
    app.config.globalProperties.$dayjs = dayjs;
    app.mount("#idx_release_list");
  });
});

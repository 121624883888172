import Cookies from "js-cookie";

class RecaptchaV2 {
  static start() {
    document.addEventListener("DOMContentLoaded", () => {
      $("#verify_recaptcha_modal_btn").click(() => {
        var token = $("#g-recaptcha-response").val();
        $.ajax({
          type: "GET",
          url: "/api/recaptchas",
          cache: false,
          data: { token: token, version: "v2" },
          success: function (res) {
            try {
              var box = $(".verify_recaptcha_modal");
              if (res.res.success === false) {
                alert("認証に失敗しました。");
                grecaptcha.reset();
              } else {
                Cookies.set("recaptcha_auth", true, { expires: 30, path: "/" });
                box.removeClass("verify_recaptcha_js_modal");
              }
            }
            catch (e) {
              // grecaptcha読み込みエラー以外はbugsnagに通知しておく
              if (e != "ReferenceError: grecaptcha is not defined") {
                window.webpackBugsnagClient.notify(e);
              }
            }
          },
          error: function (_data, _status, _headers, _config) {
          }
        });
      });
    });
  }
}

export default RecaptchaV2;

import router from "~/common/router";
import { createApp } from "vue";

router("static_pages", ["pricelist"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    const app = createApp({
      methods: {
        clickPlan(tabName) {
          this.scrollToPriceListAccordion();
          document.getElementById(tabName).checked = true;
          this.pricelistAccordionSlideDown();
        },
        scrollToPriceListAccordion() {
          const element = document.getElementById("pricelist_accordion");
          const rect = element.getBoundingClientRect();
          const position = rect.top;
          window.scrollBy({
            top: position,
            behavior: "smooth"
          });
        },
        pricelistAccordionSlideDown() {
          this.pricelistAccordion = true;
        }
      }
    });
    app.mount("#pricelist");
  });
});

<template>
  <div class="release01">
    <a :href="idxRelease.url">
      <div class="clearfix">
        <div class="release_info">
          <div
            v-if="idxRelease.showTopImage('smallIndex')"
            class="release_img01 mr20"
          >
            <span><img
              :src="idxRelease.showTopImage('smallIndex')"
              :srcset="idxRelease.showTopImage('smallIndex') + ' 1x, ' + idxRelease.showTopImage('') + ' 2x'"
              alt=""
            ></span>
          </div>
          <div>
            <h2>
              {{ idxRelease.title }}
            </h2>
            <h3>
              {{ idxRelease.companyName }}
              <span class="release_label01">プレスリリース</span>
            </h3>
            <p class="release_time">
              <span>!</span>
              <time>{{ $dayjs(idxRelease.sendDate).format('YYYY年MM月DD日 HH時') }}</time>
            </p>
            <ul class="release_tag">
              <li class="release_genre">
                <span>a</span>
                <span>{{ idxRelease.genre }}</span>
              </li>
              <li class="release_category">
                <span>a</span>
                <span>{{ idxRelease.category }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </a>
    <fav-button
      :reader="reader"
      :article-ids="articleIds"
      :article-id="idxRelease.articleId"
    />
  </div>
</template>

<script>
import FavButton from "../../Common/FavButton";
export default {
  name: "IdxRelease",
  components: { FavButton },
  props: {
    "idxRelease": {
      type: Object,
      default: () => ({}),
      require: true
    },
    "reader": {
      type: Boolean,
      default: false,
      require: true
    },
    "articleIds": {
      type: Array,
      default: () => [],
      require: true
    }
  }
};
</script>

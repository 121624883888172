import router from "~/common/router";
import { createApp } from "vue";
import ArticleList from "~/components/Articles/ArticleList";
import * as dayjs from "dayjs";

router("article_genres", ["show"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    const app = createApp({
      el: "#idx_release_list",
      components: { ArticleList },
      data() {
        return {
          contentsSideRightIsFixed: false
        };
      },
      mounted() {
        window.addEventListener("scroll", this.handleContentsRightSide);
      },
      destroyed() {
        window.removeEventListener("scroll", this.handleContentsRightSide);
      },
      methods: {
        handleContentsRightSide() {
          this.contentsSideRightIsFixed = window.scrollY >= 267;
        },
      },
    });
    app.config.globalProperties.$dayjs = dayjs;
    app.mount("#idx_release_list");
  });
});

import BaseModel from "~/models/BaseModel";
import * as dayjs from "dayjs";

class ArticleModel extends BaseModel {
  showTopImage(page) {
    return this.topImage ? this.addSizeParameterToUrl(page) : "/static/image/common/logo_large.png";
  }
  // リサイズ用のパラメータを付ける
  addSizeParameterToUrl(page) {
    if (page === "") return this.topImage;

    let url = new URL(this.topImage);
    const sizeParams = {
      smallIndex: "small_index", // 95*75
      index: "index", // 200*140
      spTopIndex: "sp_top_index", // 380*206
    };
    url.searchParams.append("size", sizeParams[page]);
    return url.href;
  }
  // 現在時刻から24時間以内の場合、「○時間前」を返し、それ以外の場合は「Y年n月j日 G時」を返します。
  // 元コード app/helpers/application_helper.rb
  liveTimeString() {
    const now = new Date();
    const sendDatetime = new Date(this.sendDate);
    if (now.setDate(now.getDate() - 1) < sendDatetime) {
      const diff = Math.floor((new Date() - sendDatetime.getTime()) / (1000 * 60 * 60));
      return (diff > 0) ? diff + "時間前" : "1時間以内";
    } else {
      return this.sendDate;
    }
  }
  showNewTag() {
    const now = new Date();
    const sendDatetime = new Date(this.sendDate);
    return now.setDate(now.getDate() - 1) < sendDatetime;
  }
  showSendDate() {
    return dayjs(this.sendDate).format("YYYY年MM月DD日 HH時");
  }
}
export default ArticleModel;

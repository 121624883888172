<template>
  <div
    v-show="reader"
    style="text-align:right;"
  >
    <span
      v-if="faved"
      style="font-size:20pt; cursor:pointer; color:#f9b403; font-family: CONDENSEicon, sans-serif;"
      @click="deleteFavorite"
    >b</span>
    <span
      v-else
      style="font-size:20pt; cursor:pointer; color:#ececec; font-family: CONDENSEicon, sans-serif;"
      onMouseOver="this.style.color='#f9b403'"
      onMouseOut="this.style.color='#ececec'"
      @click="createFavorite"
    >b</span>
  </div>
</template>

<script>
import { axios } from "~/libs/vp-api-axios";
export default {
  name: "FavButton",
  props: {
    articleId: {
      type: Number,
      default: 0,
      require: true
    },
    reader: {
      type: Boolean,
      default: false,
      require: true
    },
    articleIds: {
      type: Array,
      default: () => [],
      require: true
    }
  },
  data() {
    return { ids: this.articleIds };
  },
  computed: {
    faved() {
      return this.ids.find(id => id === this.articleId) > 0;
    }
  },
  watch: {
    articleIds(val) {
      this.ids = val;
    }
  },
  methods: {
    createFavorite() {
      axios.post("/api/v1/favorites", { article_id: this.articleId }).then(() => {
        // toppageの処理 本コンポーネント自体が状態を保持する
        this.ids = [...this.ids, this.articleId];
        // readers/releaseの処理 親コンポーネントが状態を保持する
        this.$emit("updateFavorite", { type: "add", id: this.articleId });
      });
    },
    deleteFavorite() {
      axios.delete("/api/v1/favorites/" + this.articleId).then(() => {
        // toppageの処理 本コンポーネント自体が状態を保持する
        this.ids = this.ids.filter(id => id !== this.articleId);
        // readers/releaseの処理 親コンポーネントが状態を保持する
        this.$emit("updateFavorite", { type: "del", id: this.articleId });
      });
    }
  }
};
</script>

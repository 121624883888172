<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
import Cookies from "js-cookie";

export default ({
  data() {
    return {};
  },
  mounted() {
    // 認証に成功してcookieを持っている場合は実行しない
    if(Cookies.get("recaptcha_auth") === "true"){ return; }

    this.initReCaptcha();
  },
  methods: {
    initReCaptcha: function () {
      let script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=" + window.recaptcha;
      script.onload = this.recaptchaOnload;
      document.head.appendChild(script);
    },
    recaptchaOnload() {
      setTimeout(function () {
        if (typeof grecaptcha === "undefined") {
          this.initReCaptcha();
        } else {
          this.recaptchaExecute();
        }
      }.bind(this), 100);
    },
    recaptchaExecute() {
      grecaptcha.ready(function () {
        grecaptcha.execute(window.recaptcha, { action: "homepage" }).then(function (token) {
          $.ajax({
            type: "GET",
            url: "/api/recaptchas",
            cache: false,
            data: { token: token, version: "v3" },
            success: function (res) {
              if (res && res.check_recaptcha === false) {
                var box = $(".verify_recaptcha_modal");
                box.addClass("verify_recaptcha_js_modal");
              }
            },
            error: function (_data, _status, _headers, _config) {
            }
          });
        }, function (_reason) {
        });
      });
    }
  }
});
</script>

import camelCase from "camelcase";
import snakecaseKeys from "snakecase-keys";

class BaseModel {
  constructor(init) {
    Object.assign(this, camelizeKeys(init));
  }

  toApiParams() {
    return snakecaseKeys(this);
  }
}
const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};
export default BaseModel;

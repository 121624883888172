
import InfiniteLoading from "v3-infinite-loading/lib/v3-infinite-loading.es.js";
import "v3-infinite-loading/lib/style.css";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InfiniteLoadingContent",
  components: {
    InfiniteLoading
  },
  setup(_, { emit }) {
    const loading = ref(false);

    const executeLoading = ($state) => {
      emit("on-loading", $state);
    };

    const infiniteHandler = ($state) => {
      // loadingに時間がかかった場合、何度も実行されるのでloading中はreturn
      executeLoading($state);
    };

    const infiniteAttemptLoad = () => {
      // HACK: vue-infinite-loadingがTS対応していないのでignoreにして回避
      // @ts-ignore
      const infiniteLoading = ref();
      infiniteLoading.value.attemptLoad();
    };

    const isLoaded = () => {
      loading.value = false;
    };

    return {
      infiniteHandler,
      infiniteAttemptLoad,
      isLoaded
    };
  }
});

import router from "~/common/router";
import { createApp } from "vue";
import ArticleList from "~/components/Articles/ArticleList";
import * as dayjs from "dayjs";
import { ObserveCarouselPagination } from "~/lib/CarouselPagination";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

router("articles", ["index"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    const app = createApp({
      el: "#idx_release_list",
      components: { ArticleList },
      data() {
        return {
          contentsSideRightIsFixed: false,
        };
      },
      mounted() {
        window.addEventListener("scroll", this.handleContentsRightSide);
      },
      destroyed() {
        window.removeEventListener("scroll", this.handleContentsRightSide);
      },
      methods: {
        handleContentsRightSide() {
          this.contentsSideRightIsFixed = window.scrollY >= 267;
        },
      },
    });
    app.config.globalProperties.$dayjs = dayjs;
    app.use(FloatingVue);
    app.mount("#idx_release_list");
  });
});

router("articles", ["show"], () => {
  document.addEventListener("DOMContentLoaded", () => {
    ObserveCarouselPagination();
  });
});
